import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common/commonService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { MailSettingsService } from './mailsettings.service';

@Component({
  templateUrl: 'mailsettings.component.html'
})
export class MailSettingsComponent implements OnInit {
  @ViewChild('mailSettingsForm', { read: NgForm, static: false })
  mailSettingsForm: NgForm;
  model: any = {};

  constructor(private _CommonService: CommonService,
      private _mailSettingsService: MailSettingsService) {
  }
  
  ngOnInit(): void {
   this.getMailSettings();
  }

  getMailSettings() {
    this._CommonService.showLoading();
    this._mailSettingsService.getMailSettings()
      .subscribe((res: any) => {
        this._CommonService.hideLoading();
        if (res.data) {
          this.model = res.data;
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg("Error", error);
      });
  }

  formSubmit(): void {
    if (this.mailSettingsForm.valid) {
      this._CommonService.showLoading();
      this._mailSettingsService.mailSettingsUpdate(this.model)
      .subscribe((res: any) => {
        if (res && res.messageType === 'success') {
            this._CommonService.toastSuccessMsg('Success', 'Mail settings updated successfully.');
            this._CommonService.hideLoading();
        } else {
          this._CommonService.toastErrorMsg('Error', 'Mail Settings not updated, please check.');
          this._CommonService.hideLoading();
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg("Error", error);
      });
    }
  }
}
