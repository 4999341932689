import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'inquiryFilter'
})
export class InquiryFilterPipe implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(array, row => (row.userName && row.userName.toLowerCase().indexOf(query.toLowerCase()) > -1)
      || (row.companyName && row.companyName.toLowerCase().indexOf(query.toLowerCase()) > -1)
      || (row.service && row.service.toLowerCase().indexOf(query.toLowerCase()) > -1)
      || (row.createdOn && row.createdOn.toLowerCase().indexOf(query.toLowerCase()) > -1)
      );
    }
    return array;
  }

}
