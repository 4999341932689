import { Component, OnInit } from '@angular/core';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginuserdetail: any;
  authenticationData: any = {};
  userData: any = {};
  emailPattren = dataConstant.EmailPattren;
  isSubmit = false;

  constructor(public _commonService: CommonService,
    public router: Router) { }

  ngOnInit() {
  }

  forgotPassword(forgotPasswordForm) {
    this.isSubmit = true;
    if (forgotPasswordForm.form.invalid) {
      return;
    } else {

    }
  }
}
