import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {

  constructor(public _commonService: CommonService) { }
  GetSentMailHistory() {
    return this._commonService.get(urlConstant.accountModule.GetSentMailHistory)
      .pipe(map((x: Response) => x));
  }
}
