import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { _ } from 'underscore';
import { InquiryService } from './inquiry.service';
import { CommonService } from '../../common/commonService';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company/company.service';
import { dataConstant } from '../../constant/dataConstant';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  serviceType = [];
  companyList = [];
  inquiryList = [];
  orgInquiryList = [];
  filterQuery = '';
  userId: any;
  modalReference: any;
  searchCompany = undefined;
  searchService = undefined;
  searchStartDate = null;
  searchEndDate = null;
  
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };

  constructor(private _inquiryService: InquiryService,
    private _CommonService: CommonService,
    private _companyService: CompanyService,
    private route: ActivatedRoute, private router: Router) {
      this.serviceType = dataConstant.ServiceType;
    this.route.params.subscribe(params => {
      this.userId = params['userId'];
    });
  }

  ngOnInit() {
      this.GetSentMailHistory();
      this.getCompany();
  }

  GetSentMailHistory() {
    this._CommonService.showLoading();
    this._inquiryService.GetSentMailHistory().subscribe((res: any) => {

      if (res && res.data) {
        this.inquiryList = res.data;
        this.orgInquiryList = _.clone(this.inquiryList);
        this._CommonService.hideLoading();
      } else {
        this._CommonService.toastErrorMsg(null, res.message, null);
      }
    }, error => {
      this._CommonService.hideLoading();
      this._CommonService.toastErrorMsg('Error', error);
    });
  }

  getCompany() {
    this._CommonService.showLoading();
    this._companyService.getCompany()
      .subscribe((res: any) => {
        this._CommonService.hideLoading();
        if (res.data) {
          this.companyList = res.data;
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg("Error", error);
      });
  }

  changeFilter() {
    if (!this.searchCompany || !this.searchService || !this.searchStartDate || !this.searchEndDate) {
      this.inquiryList = _.clone(this.orgInquiryList);
    }
    if (this.searchCompany) {
      this.inquiryList = _.filter(this.orgInquiryList, { 'companyName': this.searchCompany });
    }
    if (this.searchService) {
      this.inquiryList = _.filter(this.orgInquiryList, { 'service': this.searchService });
    }
    if(this.searchStartDate){
      this.inquiryList = _.filter(this.orgInquiryList, item =>{
        if(new Date(item.createdOn) >= new Date(this.searchStartDate)){
          return item;
        }
      });
    }
    if(this.searchEndDate){
      this.inquiryList = _.filter(this.orgInquiryList, item =>{
       if(new Date(item.createdOn) <= new Date(this.searchEndDate)){
         return item;
       }
      });
    }
    if(this.searchStartDate && this.searchEndDate){
      this.inquiryList = _.filter(this.orgInquiryList, item =>{
        if(new Date(item.createdOn) >= new Date(this.searchStartDate) && new Date(item.createdOn) <= new Date(this.searchEndDate)){
          return item;
        }
      });
    }
  }
  

}
