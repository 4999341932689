import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../common/commonService';
import { TestimonialService } from './testimonial.service';
import { NgbdModalContentComponent } from '../../shared/ngbd-modal-content/ngbd-modal-content.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  @ViewChild('testimonialForm', { read: NgForm, static: false })
  testimonialForm: NgForm;
  model: any = {};
  modalReference: any;
  filterQuery = '';
  testimonialList: any = [];
  imageDetail: any;
  uploadFile: any;
  uploadAttachmentList: any = [];
  @ViewChild('testimonialDetails', null) testimonialDetailsContent: TemplateRef<any>;

  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  
  constructor(private _CommonService: CommonService,
    private _testimonialService: TestimonialService,
    private _modalService: NgbModal,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.getTestimonial();
  }

  getTestimonial() {
    this._CommonService.showLoading();
    this._testimonialService.getTestimonial()
      .subscribe((res: any) => {
        this._CommonService.hideLoading();
        if (res.data) {
          this.testimonialList = res.data;
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg("Error", error);
      });
  }

  addNew(item) {
    this.model = {};
    this.imageDetail = null;
    if (item) {
      this.model = item;
      this.imageDetail = this.model.image;
    }
    this.modalReference = this._modalService.open(this.testimonialDetailsContent, this.ngbModalOptions);
  }

  async onChangePhotoFromDevice(event: any) {
    console.log('Event : ', event.target.files);
 this.uploadFile = null;
    if (event.target.files && event.target.files.length > 0) {
      this.uploadFile = event.target.files[0];
      for (const key in event.target.files) {
        if (Object.prototype.hasOwnProperty.call(event.target.files, key)) {
          const file = event.target.files[key];
          const base64 = await this._CommonService.blobToBase64(file);
          this.imageDetail = base64;
        }
      }
    }
  }

  formSubmit(companyForm): void {
    debugger;
    this.model;
    if(!this.imageDetail){
      this._CommonService.toastErrorMsg("Upload Testimonial image.","Error");
       return;
    }
    if (companyForm.valid) {
      this.model.image = this.imageDetail;
      this._CommonService.showLoading();
      this._testimonialService.testimonialUpdate(this.model)
      .subscribe((res: any) => {
        if (res && res.messageType === 'success') {
            this._CommonService.toastSuccessMsg('Testimonial save successfully.','Success');
            this._CommonService.hideLoading();
            this.modalReference.close();
            this.getTestimonial();
        } else {
          this._CommonService.toastErrorMsg(res.message,"Error");
          this._CommonService.hideLoading();
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg(error,"Error");
      });
    }
  }

  delete(id) {
    const modalRef = this._modalService.open(NgbdModalContentComponent);
    modalRef.componentInstance.confirmationBoxTitle = 'Testimonial';
    modalRef.componentInstance.confirmationMessage = 'Do you want to delete?';
    modalRef.result.then((response) => {
      if (response) {
        this._testimonialService.deleteTestimonial(id).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              this.getTestimonial();
              this._CommonService.toastSuccessMsg(res.message,"Success");
            }
            else {
              this._CommonService.toastErrorMsg(res.message,"Error");
            }
          }
        }, error => {
          this._CommonService.hideLoading();
          this._CommonService.toastErrorMsg(error,"Error");
        })
      }
    });
  }

}
