import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(public _commonService: CommonService) { }
  getCompany() {
    return this._commonService.get(urlConstant.Company.GetList)
      .pipe(map((x: Response) => x));
  }

  companyUpdate(data: any) {
    return this._commonService.post(urlConstant.Company.Create, JSON.stringify(data))
      .pipe(map((x: Response) => x));
  }

  companyPasswordUpdate(data: any) {
    return this._commonService.post(urlConstant.Company.UpdatePassword, JSON.stringify(data))
      .pipe(map((x: Response) => x));
  }

  deleteCompany(id) {
    return this._commonService.get(urlConstant.Company.Delete + "?id=" + id)
      .pipe(map((x: Response) => x));
  }
}
