import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {

  constructor(public _commonService: CommonService) { }

  getTestimonial() {
    return this._commonService.get(urlConstant.Testimonial.GetList)
      .pipe(map((x: Response) => x));
  }

  testimonialUpdate(data: any) {
    return this._commonService.post(urlConstant.Testimonial.Create, JSON.stringify(data))
      .pipe(map((x: Response) => x));
  }

  deleteTestimonial(id) {
    return this._commonService.get(urlConstant.Testimonial.Delete + "?id=" + id)
      .pipe(map((x: Response) => x));
  }

}
