import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../common/commonService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CompanyService } from './company.service';
import { NgbdModalContentComponent } from '../../shared/ngbd-modal-content/ngbd-modal-content.component';

@Component({
  templateUrl: 'company.component.html'
})
export class CompanyComponent implements OnInit {
  @ViewChild('companyForm', { read: NgForm, static: false })
  companyForm: NgForm;
  model: any = {};
  modalReference: any;
  filterQuery = '';
  companyList: any = [];
  @ViewChild('companyDetails', null) companyDetailsContent: TemplateRef<any>;
  @ViewChild('companyPassword', null) companyPasswordContent: TemplateRef<any>;

  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  
  constructor(private _CommonService: CommonService,
    private _companyService: CompanyService,
    private _modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getCompany();
  }

  getCompany() {
    this._CommonService.showLoading();
    this._companyService.getCompany()
      .subscribe((res: any) => {
        this._CommonService.hideLoading();
        if (res.data) {
          this.companyList = res.data;
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg("Error", error);
      });
  }

  addNew(item) {
    this.model = {};
    if (item) {
      this.model = item;
    }
    this.modalReference = this._modalService.open(this.companyDetailsContent, this.ngbModalOptions);
  }

  updatePassword(item) {
    this.model = item;
    this.modalReference = this._modalService.open(this.companyPasswordContent, this.ngbModalOptions);
  }

  formSubmit(companyForm): void {
    if (companyForm.valid) {
      this._CommonService.showLoading();
      this._companyService.companyUpdate(this.model)
      .subscribe((res: any) => {
        if (res && res.messageType === 'success') {
            this._CommonService.toastSuccessMsg('Company save successfully.','Success');
            this._CommonService.hideLoading();
            this.modalReference.close();
            this.getCompany();
        } else {
          this._CommonService.toastErrorMsg(res.message,"Error");
          this._CommonService.hideLoading();
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg(error,"Error");
      });
    }
  }

  formSubmitPassword(companyForm): void {
    if (companyForm.valid) {
      if(this.model.confirmpassword != this.model.password){
        return;
      }
      this._CommonService.showLoading();
      this._companyService.companyPasswordUpdate(this.model)
      .subscribe((res: any) => {
        if (res && res.messageType === 'success') {
            this._CommonService.toastSuccessMsg('Password updated successfully.','Success');
            this._CommonService.hideLoading();
            this.modalReference.close();
            this.getCompany();
        } else {
          this._CommonService.toastErrorMsg(res.message,"Error");
          this._CommonService.hideLoading();
        }
      }, error => {
        this._CommonService.hideLoading();
        this._CommonService.toastErrorMsg(error,"Error");
      });
    }
  }

  delete(id) {
    const modalRef = this._modalService.open(NgbdModalContentComponent);
    modalRef.componentInstance.confirmationBoxTitle = 'Compnay';
    modalRef.componentInstance.confirmationMessage = 'Do you want to delete?';
    modalRef.result.then((response) => {
      if (response) {
        this._companyService.deleteCompany(id).subscribe((res: any) => {
          if (res) {
            if (res.status == 200) {
              this.getCompany();
              this._CommonService.toastSuccessMsg(res.message,"Success");
            }
            else {
              this._CommonService.toastErrorMsg(res.message,"Error");
            }
          }
        }, error => {
          this._CommonService.hideLoading();
          this._CommonService.toastErrorMsg(error,"Error");
        })
      }
    });
  }
}
