import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navCompanyItems, navItems } from '../../_nav';
import { Router } from '@angular/router';
import { CommonService } from '../../common/commonService';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {


  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  loginUser = this._commonService.getLoggedInUser();
  userShortName = null;
  public username = null;

  constructor(public _router: Router,
    public _commonService: CommonService,
    @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    // Temp Solve need to remove

    if (!this.loginUser) {
      this._router.navigate(['/login']);
    }

    if (this.loginUser.IsAdmin) {
      this.navItems = navItems;
    }
    else {
      this.navItems = navCompanyItems;
    }

    this.username = this.loginUser.UserName;
    if (this.username) {
      const matches = this.username.match(/\b(\w)/g);
      this.userShortName = matches.join('');
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    this._router.navigate(['/login']);
  }
}
