import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailSettingsService {
  constructor(public _commonService: CommonService) { }
  getMailSettings() {
    return this._commonService.get(urlConstant.MailSettings.GetMailSettings)
      .pipe(map((x: Response) => x));
  }

  mailSettingsUpdate(data: any) {
    return this._commonService.post(urlConstant.MailSettings.MailSettingsUpdate, JSON.stringify(data))
      .pipe(map((x: Response) => x));
  }
}
