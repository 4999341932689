import { Component, OnInit } from '@angular/core';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loginuserdetail: any;
  resetPasswordModel: any = {};
  userData: any = {};
  isSubmit = false;
  passwordPattern = dataConstant.PasswordPattern;

  constructor(public _commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.resetPasswordModel.UserId = params['userId'];
      this.resetPasswordModel.Token = params['token'];
    });
  }

  resetPasssword(resetPasswordForm) {
    this.isSubmit = true;
    if (resetPasswordForm.form.invalid) {
      return;
    }
    this._commonService.showLoading();
  }

}
